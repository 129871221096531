import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Domain = RepositoryFactory.get('domain')

const state = {
  domains: [],
  domain: null
}

const actions = {
  async fetchDomains({ commit }, params) {
    const res = await Domain.fetch(params)
    commit('setDomains', res.data || [])
  },
  async updateDomain({ commit }, { id, ...params }) {
    const res = await Domain.update(id, params)
    commit('updateDomain', res.data || {})
  }
}

const mutations = {
  setDomains(state, domains) {
    state.domains = domains
  },
  updateDomain(state, domain) {
    const foundIndex = state.domains.findIndex(d => d.id === domain.id)
    if (foundIndex > -1) {
      state.domains.splice(foundIndex, 1, domain)
    }
  },
  setDomain(state, domain) {
    state.domain = domain
  },
}

const getters = {
  getDomains: state => state.domains,
  getDomain: state => state.domain
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}