import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const { Statistic } = RepositoryFactory.get('statistic')
import get from 'lodash-es/get'

const state = {
  statistic: [],
}

const actions = {
  async fetchStatistic({ commit }, params) {
    const res = await Statistic.getStatistic(params)
    return commit('setStatistic', get(res, 'data.data', []))
  }
}
const mutations = {
  setStatistic(state, data) {
    state.statistic = data
  }
}

const getters = {
  getStatistic: state => {
    return state.statistic
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
