<template>
	<v-layout
		align-center
		justify-center
		class="background-authen full-width full-height"
	>
		<BackgroundAnimation />
		<v-flex xs11 md9 lg7>
			<v-layout wrap>
				<v-flex
					xs12
					md6
					sm6
					class="pureground-authen pa-8 d-flex flex-column justify-space-between primary--text"
				>
					<div>
						<div class="headline font-weight-bold primary--text">
							Quản lý tên miền
						</div>
						<div class="caption">
							/ Không phận sự miễn vào /
						</div>
					</div>
					<Contact v-if="displayContact" />
				</v-flex>
				<v-flex xs12 md6 sm6>
					<router-view></router-view>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>
<script>
import BackgroundAnimation from '@/components/animation/background'
import Contact from '@/modules/tutorial/Contact'
export default {
	components: {
		Contact,
		BackgroundAnimation
	},
	data() {
		return {
			displayContact: true
		}
	},
	methods: {
		onResizeWindow(width) {
			this.displayContact = width >= 600 ? true : false
		}
	}
}
</script>
<style lang="scss" scoped>
.background-authen {
	background: url('https://online-learning-izteach-3-aws-source-bucket.s3.ap-southeast-1.amazonaws.com/prod/XAM09899.jpeg')
		center center no-repeat;
	height: 80%;
	background-position: center;
	background-repeat: repeat;
	background-size: contain;
}
.pureground-authen {
	background: white center center no-repeat;
	opacity: 0.8;
	background-size: cover;
}
@media only screen and (max-width: 600px) {
	.pureground-authen {
		height: auto;
	}
}
</style>
