import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Client = RepositoryFactory.get('client')

const state = {
	client: {},
	clients: [],
	admins: [],
	newClient: {},
	confirmCode: ''
}

const actions = {
	async createNewClient({ commit }, data) {
		const res = await Client.signUp(data)
		commit('setNewClient', res.data)
		return res.data || {}
	},
	async createNewAdmin(_, data) {
		const res = await Client.create(data)
		return res.data || {}
	},
	async fetchAdmin({ commit }, params) {
		const res = await Client.fetch(params)
		commit('setAdmins', res.data.Items)
	},
	async resendConfirmCode({ commit }, data) {
		const res = await Client.resendConfirmCode(data)
		commit('setConfirmCode', res.data)
		return res.data
	},
	async confirmCode({ commit }, data) {
		const res = await Client.confirm(data)
		commit('setConfirmCode', res.data)
		return res.data
	},
	async fetchClients({ commit, rootState }, params) {
		const listVendors = rootState.vendor.vendors
		if (!listVendors.length) return []
		let promises = listVendors.map(vendor => {
			return Client.fetch({
				...params,
				vendorId: vendor.id
			})
		})
		const result = await Promise.all(promises)
		let filterClients = []
		result.forEach(data => {
			let clientAdmin = data.data.Items[0]
			if (clientAdmin) {
				clientAdmin['vendorInfo'] = listVendors.find(
					vendor => vendor.id === clientAdmin.vendorId
				)
				filterClients.push(clientAdmin)
			}
		})
		commit('setClients', filterClients || [])
	},
	async fetchOne({ commit }, params) {
		const res = await Client.fetchOne(params)
		commit('setClient', res.data || [])
	}
}
const mutations = {
	setNewClient(state, newClient) {
		state.newClient = newClient
	},
	setAdmins(state, admins) {
		state.admins = admins
	},
	setClient(state, client) {
		state.client = client
	},
	setConfirmCode(state, confirmCode) {
		state.confirmCode = confirmCode
	},
	setClients(state, clients) {
		state.clients = clients
	}
}

const getters = {
	getDomain: state => {
		return state.client
	},
	getNewClient: state => {
		return state.newClient
	},
	getClients: state => {
		return state.clients
	},
	getAdmins: state => {
		return state.admins
	}
}

export default {
	state,
	actions,
	mutations,
	getters,
	namespaced: true
}
