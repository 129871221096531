<template>
  <div class="base-captcha">
    <span class="mr-3">Mã xác thực: </span>
    <canvas
      class="mr-3"
      ref="canvas"
      :width="width"
      :height="height"
      :style="{
        width: displayWidthFormatted
      }"
    ></canvas>
    <v-icon @click="reset">mdi-reload</v-icon>
  </div>
</template>
<script>
const RAND_MAX = 0.9999999999999999

export default {
  props: {
    displayWidth: {
      type: [String, Number],
      default: () => 80
    }
  },
  data() {
    let itemWidth = 110
    let height = 200
    let length = 4
    let fontSize = height - 4
    let tempCanvas = document.createElement('canvas')
    tempCanvas.width = itemWidth
    tempCanvas.height = height
    return {
      itemWidth,
      length,
      width: itemWidth * length,
      height,
      fontSize,
      code: null,
      imageLayerData: null,
      tempCanvas: tempCanvas
    }
  },
  computed: {
    displayWidthFormatted() {
      return typeof this.displayWidth == 'number'
        ? `${this.displayWidth}px`
        : this.displayWidth
    },
  },
  methods: {
    init() {
      this.code = this.randomNumber()
      return this.writeStringToImage(this.code)
    },
    reset() {
      this.init()
    },
    randomNumber() {
      let str = ''
      for(let i = 0; i < this.length; i++) {
        str += this.randomInt(0, 10)
      }
      return str
    },
    randomInt(from, to) {
      let diff = to - from
      return parseInt(Math.random() * diff) + from
    },
    validate(number) {
      return this.code == number
    },
    /**
     * @summary: draw each character in string to canvas with transformation
     * @description:
     * canvas: 440x200
     * each character: 110x200
     */
    writeStringToImage(str) {
      let canvas = this.$refs.canvas
      let ctx = canvas.getContext('2d')

      this.clearCanvas(canvas)

      ctx.fillStyle = '#000'
      ctx.font = `${this.fontSize}px Arial`
      
      for(let i in str) {
        let char = str[i]
        let imageCharWidth = this.itemWidth
        let height = this.height
        let image = this.genCharImage(char, imageCharWidth, height)

        ctx.putImageData(image, i * imageCharWidth, 0)
      }

      this.drawConstrains(ctx, this.width, this.height)
    },
    genCharImage(char, imageWidth, imageHeight) {
      let tempCanvas = this.tempCanvas
      this.clearCanvas(tempCanvas)
      
      let ctx = tempCanvas.getContext('2d')

      ctx.resetTransform()
      let transformSet = this.randomTransform()
      ctx.setTransform(...transformSet)
      ctx.fillStyle = '#000'
      ctx.font = `${this.fontSize}px Arial`
      ctx.fillText(char, 5, this.fontSize)

      return ctx.getImageData(0, 0, imageWidth, imageHeight)
    },
    clearCanvas(canvas) {
      let ctx = canvas.getContext('2d')

      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    },
    drawConstrains(ctx, width, height) {
      // draw many random lines like the caro on image
      for(let x = 0; x < width; x++) {
        let lineOpacity = parseInt(this.randomFloat(0.2, 0.6) * 100) / 100
        let lineLength = this.randomInt(height * 0.3, height)
        let lineStartY = this.randomInt(0, height - lineLength)
        let lineWidth = this.randomInt(1, 5)
        
        ctx.fillStyle = `rgba(0, 0, 0, ${lineOpacity})`
        ctx.fillRect(x, lineStartY, lineWidth, lineLength)

        x+= lineWidth - 1
      }
      
      for(let y = 0; y < height; y++) {
        let lineOpacity = parseInt(this.randomFloat(0.2, 0.6) * 100) / 100
        let lineLength = this.randomInt(width * 0.3, width)
        let lineStartX = this.randomInt(0, width - lineLength)
        let lineWidth = this.randomInt(1, 5)

        ctx.fillStyle = `rgba(0, 0, 0, ${lineOpacity})`
        ctx.fillRect(lineStartX, y, lineLength, lineWidth)

        y+= lineWidth - 1
      }
    },
    randomTransform() {
      let a = this.randomFloat(0.7, 0.99),
          b = this.randomFloat(-0.3, 0.3),
          c = this.randomFloat(-0.05, 0.05),
          d = this.randomFloat(0.7, 0.99),
          e = 0,
          f = 0
      return [a, b, c, d, e, f]
    },
    randomFloat(from, to) {
      let diff = to - from
      return Math.random() / RAND_MAX * diff + from
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss">
.base-captcha {
  display: flex;
  align-items: center;
  .v-icon {
    color: #3552BA;
    &:hover {
      color: #4663CB;
    }
  }
}
</style>




