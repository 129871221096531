import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_VENDOR + '/user'

const create = params => {
	return Repository(baseURL).create(params)
}

const fetch = params => {
	return Repository(baseURL + '/clients').fetch(params)
}
const fetchUser = params => {
	return Repository(baseURL).fetch(params)
}
const fetchOne = params => {
	return Repository(baseURL).fetchOne(params)
}

const signUp = params => {
	return Repository(baseURL + '/signup').createWithoutAccessToken(params)
}

const confirm = params => {
	return Repository(baseURL + '/confirm').fetch(params)
}
const resendConfirmCode = params => {
	return Repository(baseURL + '/resendConfirmCode').fetch(params)
}
const remove = id => {
	return Repository(baseURL).deleteWithoutAccessToken(id)
}

export default {
	create,
	fetch,
	remove,
	fetchOne,
	signUp,
	confirm,
	resendConfirmCode,
	fetchUser
}
