import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Vendor = RepositoryFactory.get('vendor')

const state = {
	vendor: {},
	vendors: [],
	siteName: 'Quản lý khách hàng'
}

const actions = {
	async fetchVendors({commit}, params) {
		const res = await Vendor.fetch(params)
		return commit('setVendors', res.data.Items || [])
	},
	async fetchVendorById({commit}, params) {
		const res = await Vendor.fetchOne(params)
		return commit('setVendor', res.data || {})
	},
	async activeNewVendor(_, params) {
		const res = await Vendor.activeNewVendor(params.id, params.body)
		return res
	},
	async addCustomDomain(_, params) {
		const res = await Vendor.addCustomDomain(params.id, params.body)
		return res
	},
	async removeVendor(_, id) {
		return await Vendor.remove(id)
	},
	async updateVendor({rootState, commit}, {id, ...params}) {
		const res = await Vendor.update(id, params)
		commit('setVendor', res.data || {})
		const newListVendor = rootState.vendor.vendors.map(i => {
			if (String(i.id) === String(res.data.id)) return res.data
			else return i
		})
		commit('setVendors', newListVendor)
		return res.data
	}
}
const mutations = {
	setVendor(state, vendor) {
		state.vendor = vendor
	},
	setVendors(state, vendors) {
		state.vendors = vendors
	}
}

const getters = {
	getVendor: state => {
		return state.vendor
	},
	getVendors: state => {
		return state.vendors.sort((a, b) => b.createdAt - a.createdAt)
	},
	getSiteName: state => {
		return state.siteName
	}
}

export default {
	state,
	actions,
	mutations,
	getters,
	namespaced: true
}
