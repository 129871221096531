import vendorRepository from '@/api/repository/vendorRepository'
import clientRepository from '@/api/repository/clientRepository'
import domainRepository from '@/api/repository/domainRepository'
import statisticRepository from '@/api/repository/statisticRepository'
const repositories = {
	vendor: vendorRepository,
	client: clientRepository,
	domain: domainRepository,
	statistic: statisticRepository
}

export const RepositoryFactory = {
	get: name => repositories[name]
}
