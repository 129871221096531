import Repository from '@/api/core'
const domainURL = process.env.VUE_APP_API_VENDOR + '/domain'

const fetch = params => {
	return Repository(domainURL).fetch(params)
}

const update = (id, params) => {
	return Repository(domainURL).update(id, params)
}

export default {
  fetch,
  update,
}
