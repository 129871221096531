import Repository from '@/api/core'
const vendorURL = process.env.VUE_APP_API_VENDOR + '/vendor'

const create = params => {
	return Repository(vendorURL).create(params)
}

const fetch = params => {
	return Repository(vendorURL).fetch(params)
}

const fetchOne = params => {
	return Repository(vendorURL).fetchOne(params)
}

const activeNewVendor = (id, params) => {
	return Repository(`${vendorURL}/active-new-vendor`).update(id, params)
}

const addCustomDomain = (id, params) => {
	return Repository(`${vendorURL}/add-custom-domain`).update(id, params)
}

const remove = id => {
	return Repository(vendorURL).deleteWithoutAccessToken(id)
}

const update = (id, params) => {
	return Repository(vendorURL).update(id, params)
}

export default {
	create,
	fetch,
	remove,
	fetchOne,
	activeNewVendor,
	addCustomDomain,
	update,
}
