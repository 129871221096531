function lazyLoad(view) {
	return () => import(`@/pages/${view}.vue`)
}
export default [
	{
		path: '/domain',
		name: 'Domain',
		component: lazyLoad('Domain'),
		meta: {
			title: 'Quản lý tên miền'
		}
	},
	{
		path: '/statistic',
		name: 'Statistic',
		component: lazyLoad('Statistic'),
		meta: {
			title: 'Thống kê dữ liệu'
		}
	},
	// {
	// 	path: '/clients',
	// 	name: 'Khách hàng',
	// 	component: lazyLoad('Clients'),
	// 	meta: {
	// 		title: 'Danh sách khách hàng'
	// 	}
	// },
	// {
	// 	path: '/client-detail/:id',
	// 	name: 'Thông tin chi tiết khách hàng',
	// 	component: lazyLoad('ClientDetail'),
	// 	meta: {
	// 		title: 'Thông tin chi tiết khách hàng'
	// 	}
	// },
	{
		path: '/domain-detail/:id',
		name: 'Thông tin chi tiết trang',
		component: lazyLoad('DomainDetail'),
		meta: {
			title: 'Thông tin chi tiết trang'
		}
	}
]
