import axios from 'axios'
import auth from '@/store/modules/auth'

const Repository = BASE_URL => ({
  count: (params, option) =>
    axios.get(
      BASE_URL + '-count',
      {
        params: { ...params },
      },
      option
    ),
  fetch: (params, option) =>
    axios.get(
      BASE_URL,
      { params: { ...params } },
      option
    ),
  fetchWithAccessToken: (params, option) => {
    if (auth.state.auth.accessToken) {
      return axios.get(
        BASE_URL,
        {
          params: { ...params },
          headers: {
            Authorization: auth.state.auth.accessToken
          }
        },
        option
      )
    }
  },
  fetchOne: (id, option) =>
    axios.get(
      BASE_URL + '/' + id,
      { params: {} },
      option
    ),
  create: (params, options) =>
    axios.post(
      BASE_URL,
      { ...params },
      {
        headers: {
          Authorization: auth.state.user.accessToken
        }
      },
      options
    ),
  createMany: (params, options) =>
    axios.post(
      BASE_URL + '/create-many',
      { ...params },
      options
    ),
  createWithoutAccessToken: (params, options) =>
    axios.post(
      BASE_URL,
      { ...params },
      options
    ),
  update: (id, params, option) =>
    axios.put(
      BASE_URL + `/${id}`,
      params,
      {
        headers: {
          Authorization: auth.state.user.accessToken
        }
      },
      option
    ),
  updateWithoutAccessToken: (id, params, option) =>
    axios.put(
      BASE_URL + `/${id}`,
      params,
      option
    ),
  deleteWithoutAccessToken: (id, option) =>
    axios.delete(
      BASE_URL + `/${id}`,
      option
    ),
  delete: (id, option) =>
    axios.delete(
      BASE_URL + `/${id}`,
      {
        headers: {
          Authorization: auth.state.auth.accessToken
        }
      },
      option
    ),
  average: (params, option) =>
    axios.get(
      BASE_URL + `-average`,
      { params: { ...params } },
      option
    )
})

export default Repository
