import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_VENDOR + '/user'
const APIHelper = () => ({
	refreshToken: id => {
		return Repository(baseURL + '/refresh').fetchOne(id)
	},
	signin: params => {
		return Repository(baseURL + '/signin').createWithoutAccessToken(params)
	}
})

export const Auth = APIHelper()
export default {
	Auth
}
