function lazyLoad(view) {
	return () => import(`@/pages/${view}.vue`)
}
export default [
	{
		path: 'signIn',
		name: 'Sign In',
		component: lazyLoad('SignIn'),
		meta: {
			title: 'Đăng nhập'
		}
	},
	{
		path: 'forgotPass',
		name: 'Forgot Password',
		component: lazyLoad('ForgotPassword'),
		meta: {
			title: 'Quên mật khẩu'
		}
	}
]
