const AUTH_USER_STORAGE_NAME = 'ol-credentials-auth-user'
import Base64 from '@/helpers/encodeBase64.js'

function _loadAuthUser() {
	const authUserEncoded = localStorage.getItem(AUTH_USER_STORAGE_NAME)
	try {
		return authUserEncoded ? JSON.parse(Base64.decode(authUserEncoded)) : null
	} catch {
		return null
	}
}

const AuthFilter = async (to, from, next) => {
	const authUser = _loadAuthUser()
	if (authUser && authUser.roles.includes('super-admin')) next()
	else next('/auth/signIn')
}
export default AuthFilter
