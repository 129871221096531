<template>
	<div>
		<v-navigation-drawer
			color="primary"
			dark
			permanent
			fixed
			touchless
			app
			width="328"
			mini-variant-width="68"
			:mini-variant.sync="isMinimized"
			:mobile-breakpoint="600"
		>
			<v-list class="pt-0">
				<v-list-item @click="$router.push('/dashboard')">
					<v-list-item-icon :class="{'mr-4': !isMinimized}">
						<v-icon>mdi-view-dashboard</v-icon>
					</v-list-item-icon>
					<v-list-item-title class="text-uppercase font-weight-bold">{{
						siteName
					}}</v-list-item-title>
					<v-list-item-action @click.stop="isMinimized = !isMinimized">
						<v-icon>mdi-chevron-double-left</v-icon>
					</v-list-item-action>
				</v-list-item>
				<v-list-item @click="$router.push('/statistic')">
					<v-list-item-icon :class="{'mr-4': !isMinimized}">
						<v-icon>mdi-chart-areaspline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="text-uppercase font-weight-bold"
							>Thống kê dữ liệu</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="$router.push('/domain')">
					<v-list-item-icon :class="{'mr-4': !isMinimized}">
						<v-icon>mdi-domain</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="text-uppercase font-weight-bold"
							>Tên miền</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>

				<v-list-item @click="onSignOut">
					<v-list-item-icon :class="{'mr-4': !isMinimized}">
						<v-icon>mdi-logout-variant</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="text-uppercase font-weight-medium"
							>Đăng xuất</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="isMinimized" @click="isMinimized = !isMinimized">
					<v-list-item-icon>
						<v-icon>mdi-chevron-double-right</v-icon>
					</v-list-item-icon>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-main>
			<router-view></router-view>
		</v-main>
	</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
	data() {
		return {
			isMinimized: false
		}
	},
	computed: {
		...mapGetters({
			siteName: 'vendor/getSiteName'
		})
	},
	methods: {
		...mapActions({
			signOut: 'auth/signOut'
		}),
		async onSignOut() {
			this.$confirm.show({
				title: 'Bạn có chắc muốn đăng xuất?',
				text: 'Hãy lưu lại các tuỳ chỉnh nếu không muốn mất nhé!',
				done: async () => {
					await this.signOut()
					this.$router.go('/auth/signIn')
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .theme--dark.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
	opacity: 0;
}
::v-deep .v-list-item--active.is-minimized {
	background-color: white;
	& .v-list-item__icon {
		color: #255cc2;
	}
}
</style>
