const AWS_S3_ROOT_BUCKET_URL = process.env.VUE_APP_AWS_BUCKET_S3

const commentEnums = {
	countTypeEnums: {
		TOTAL: 'TOTAL',
		COMMENT: 'COMMENT',
		REPLY: 'REPLY'
	}
}

const LIST_PACKAGES = [
	{text: 'Cơ bản', value: 'basic'},
	{text: 'Tiêu chuẩn', value: 'standard'},
	{text: 'Nâng cao', value: 'advance'},
	{text: 'Chuyên nghiệp', value: 'professional'},
	{text: 'Toàn diện', value: 'full-option'},
	{text: 'Tùy chỉnh', value: 'custom'}
]

const DEFAULT_IMAGE = {
	avatarProfile:
		'https://online-learning-izteach-3-test-aws-source-bucket.s3-ap-southeast-1.amazonaws.com/resource/images/23b0bfb0-c00b-11e9-a060-17d3861b7a5b/avatar.png',
	card:
		process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/SD-default-image.png',
	banner:
		process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/banner-default.jpg',
	avatar:
		process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/default-avatar.png',
	logo: process.env.VUE_APP_AWS_BUCKET_S3_DEFAULT_DATA + '/root-logo.png',
	certificate:
		'https://test-izteach-resource-bigdat.s3-ap-southeast-1.amazonaws.com/resource/documents/23b0bfb0-c00b-11e9-a060-17d3861b7dev/1605091622608'
}

const PERMISSION = {
	ADMIN: {
		name: 'admin',
		getByCreatedBy: false,
		allScreens: true,
		screens: []
	},
	TEACHER: {
		name: 'teacher',
		getByCreatedBy: true,
		allScreens: false,
		screens: [
			'Khóa Học Online Chi Tiết',
			'Điểm kiểm tra trắc nghiệm',
			'Bài tập',
			'Báo cáo học tập',
			'Khóa học online',
			'Detail Online Course',
			'Detail Test & Quiz'
		],
		groups: ['Nội dung khóa học']
	}
}
const DEFAULT_LABELS = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z'
]

export default {
	DEFAULT_IMAGE,
	AWS_S3_ROOT_BUCKET_URL,
	commentEnums,
	PERMISSION,
	DEFAULT_LABELS,
	LIST_PACKAGES
}
