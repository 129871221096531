import axios from 'axios'
const baseURL = process.env.VUE_APP_API_STATISTIC + '/statistic'

const headers = {
  'X-AUTH-KEY': 'a06fa1ef4fb94be3a52612015dd4ae18',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

const APIHelper = () => ({
  getStatistic: params => {
    return axios.post(
      baseURL + '/get-referer-data/0/1000',
      { ...params },
      {
        headers: headers
      }
    )
  }
})

export const Statistic = APIHelper()
export default {
  Statistic
}
