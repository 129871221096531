import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthenLayout from '@/templates/layout/AuthenLayout'
import AccountLayout from '@/templates/layout/AccountLayout'
import authRoutes from '@/router/authRoutes'
import authFilter from '@/router/authFilter'
import hiddenRoutes from '@/router/hiddenRoutes'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/domain'
	},
	{
		path: '/account',
		component: AccountLayout,
		beforeEnter: authFilter,
		children: [...hiddenRoutes]
	},
	{
		path: '/auth',
		redirect: '/auth/signIn',
		name: 'AuthLayout',
		component: AuthenLayout,
		children: authRoutes
	}
]

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes
})

export default router
